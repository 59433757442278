import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { CountryModalComponent } from './country-modal/country-modal.component';



@NgModule({
  declarations: [HeaderComponent,CountryModalComponent],
  imports: [
    CommonModule
  ],
  exports:[HeaderComponent,CountryModalComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonmodulesModule { }
