import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Config {
}

export const logo = '"../../assets/images/logoLanding.svg';

export const preloader = '<img src="../assets/image/loader.gif">';

export const currency = "INR";

export const avatar = "../assets/avatars/";

//URL
export const url ="https://api.galicrickets.com/api/";
export const aws_url ="https://galicricket.s3.ap-south-1.amazonaws.com/FinalPlayers11Json/";
export const payment_url="https://galicrickets.com/transaction/process";
export const ifscUrl = "https://ifsc.razorpay.com/";
export const appVersion ="1.2";