import { Component } from '@angular/core';
import { NavController, MenuController, Platform } from '@ionic/angular';
import { CommonserviceService } from './services/commonservice.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: any;
  selectedImage: any = '../assets/4.jpg';
  result: any;
  constructor(public commonService: CommonserviceService, public navCtrl: NavController, public menu: MenuController, public platform : Platform, public deeplink:Deeplinks) { 
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  addCash() {
    this.dissableBackevent();
    this.menu.close();
    this.navCtrl.navigateForward("/add-cash");
  }
  wallet() {
    this.menu.close();
    localStorage.setItem('tabValue','3')
    this.navCtrl.navigateForward("/my-account")
  }
  logout() {
    this.menu.close();
    this.commonService.logout();
  }
  more(){
    this.menu.close();
    this.navCtrl.navigateForward('/more');
  }
  menuOpened(){
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.commonService.commonApiService('get','profile','').then((result:any)=>{
      this.result = result;
      this.selectedImage = result.data.profile_image;
      if(this.selectedImage == "" || this.selectedImage == undefined)
      {
        this.selectedImage="../assets/4.jpg";
      }
    });
  }
  profile(){
    this.menu.close();
    this.navCtrl.navigateForward('/personal-details');
  }
  
  dissableBackevent(){
    console.log(this.platform)
  }
  moreSetting(type)
  {
    this.menu.close();
    this.navCtrl.navigateForward('/moresettings?type=' + type);
  }

}
